<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template>
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">
                        {{ "CREATE_NEW_ADAPTIVE_CHANNEL" | translate }}
                    </h1>
                    <h1 *ngIf="isClone">{{ "CLONE_ADAPTIVE_CHANNEL" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_ADAPTIVE_CHANNEL" | translate }} - {{ channel.name }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- Adaptive -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [isRestartRequiredTip]="isEdit"
                            [duplicateName]="channelNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                            [patternErrorMessage]="'CAN_NOT_CONTAIN_SPECIAL_CHARACTERS' | translate"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            [infoTooltip]="'TOOLTIP.CHANNEL_TAGS' | translate"
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="adaptive"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>
                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="channel.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Processing Cluster -->
                        <div class="form-group">
                            <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                                >{{ "PROCESSING_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon icon="info-circle" [ngbTooltip]="PCContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #PCContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.CHANNEL_PROCESSING_CLUSTER" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-cluster-select
                                name="broadcaster_cluster_id"
                                [(model)]="channel.broadcaster_cluster_id"
                                [ngModel]="channel.broadcaster_cluster_id"
                                required="true"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                                (modelChange)="reloadBroadcasterTargets(channel.broadcaster_cluster_id)"
                            ></zx-cluster-select>
                            <div *ngIf="form.controls.broadcaster_cluster_id?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.broadcaster_cluster_id?.errors.required">
                                    {{ "PROCESSING_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                </div>
                            </div>
                        </div>
                        <!-- Target Broadcaster/s -->
                        <div class="form-group">
                            <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && targetBroadcasters.errors }"
                                >{{ "TARGET_BROADCASTER/S" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon icon="info-circle" [ngbTooltip]="tbContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #tbContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.CHANNEL_TARGET_BROADCASTER" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <ng-select
                                id="target_broadcaster_id"
                                name="target_broadcaster_id"
                                [items]="targetBXs"
                                [clearable]="false"
                                bindValue="id"
                                bindLabel="name"
                                groupBy="type"
                                placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                                [(ngModel)]="channel.broadcaster_id"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && targetBroadcasters.errors }"
                                [disabled]="!channel.broadcaster_cluster_id || targetBXsLoading"
                                #targetBroadcasters="ngModel"
                                [loading]="targetBXsLoading"
                                required
                            >
                                <ng-template ng-optgroup-tmp let-item="item">
                                    <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                    <span *ngIf="!item.type">{{ item.name }}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                    <span *ngIf="!item.type">{{ item.name }}</span>
                                </ng-template>
                            </ng-select>
                            <div *ngIf="targetBroadcasters.invalid" class="invalid-feedback">
                                <div *ngIf="targetBroadcasters.errors.required">{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>

                        <!-- Alternative Channel -->
                        <div class="form-group">
                            <label
                                for="alternative_channel_select"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alternative_channel_select?.errors }"
                                >{{ "ALTERNATIVE_CHANNEL" | translate }} ({{ "DISASTER_RECOVERY" | translate }})</label
                            >
                            <zx-adaptive-channel-select
                                id="alternative_channel_select"
                                name="alternative_channel_select"
                                [model]="channel.alt_channel_id || null"
                                (modelChange)="channel.alt_channel_id = $event"
                                [filterFunction]="alternativeChannelFilter"
                                [clearable]="true"
                            ></zx-adaptive-channel-select>
                        </div>

                        <!-- Log SCTE-35 -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="log_scte" name="log_scte" [(ngModel)]="channel.log_scte" />
                                <label class="form-check-label" for="log_scte">{{ "LOG_HLS_AD_MARKERS" | translate }}</label>
                            </div>
                        </div>

                        <!-- Sources -->
                        <div class="form-group">
                            <label for="sources" [ngClass]="{ 'is-invalid': form.submitted && (selectedBitrates.length === 0 || autoPullDisabled) }"
                                >{{ "RENDITIONS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <div class="table-responsive">
                                <table
                                    class="table table-sm bordered m-0"
                                    [ngClass]="{ 'is-invalid': form.submitted && (selectedBitrates.length === 0 || autoPullDisabled) }"
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col">{{ "SOURCE" | translate }}</th>
                                            <th scope="col" class="w-100px">{{ "BITRATE" | translate }} [kbps]</th>
                                            <th scope="col" class="w-50px text-end">
                                                <fa-icon
                                                    class=""
                                                    icon="info-circle"
                                                    [ngbTooltip]="BIContent"
                                                    triggers="hover click"
                                                    [closeDelay]="500"
                                                ></fa-icon>
                                                <ng-template #BIContent>
                                                    <p class="mb-0">{{ "TOOLTIP.CHANNEL_BITRATE_INSTRUCTIONS" | translate }}</p>
                                                </ng-template>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="!sourcesLoading && (sources.length > 0 || selectedBitrates.length > 0)">
                                        <tr *ngFor="let bitrate of selectedBitrates; index as index">
                                            <td class="maxw-100px ellipsis">
                                                <zx-source
                                                    [model]="bitrate.source"
                                                    [showStatusIcon]="true"
                                                    [showStatusText]="false"
                                                    [showLink]="true"
                                                    [showTag]="false"
                                                    [ngClass]="{ 'is-invalid': form.submitted && bitrate.source.disable_autopull }"
                                                ></zx-source>
                                                <div *ngIf="form.submitted && autoPullDisabled" class="invalid-feedback">
                                                    <div *ngIf="bitrate.source.disable_autopull">{{ "AUTO_PULL_REQUIRED_FOR_ADAPTIVE" | translate }}.</div>
                                                </div>
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    class="form-control form-control-sm form-control-xs"
                                                    id="bitrate_{{ index }}"
                                                    name="bitrate_{{ index }}"
                                                    [(ngModel)]="bitrate.kbps"
                                                    pattern="^\d*[1-9]\d*$"
                                                    placeholder="{{ 'BITRATE' | translate }}"
                                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls['bitrate_' + index]?.errors }"
                                                    required
                                                />
                                                <div *ngIf="form.controls['bitrate_' + index]?.invalid" class="invalid-feedback">
                                                    <div *ngIf="form.controls['bitrate_' + index]?.errors.required">
                                                        {{ "BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                    </div>
                                                    <div *ngIf="form.controls['bitrate_' + index]?.errors.pattern">{{ "INVALID_NUMBER" | translate }}.</div>
                                                </div>
                                            </td>
                                            <td class="text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                    (click)="deselectAdaptiveSource(bitrate.source_id, bitrate.source)"
                                                >
                                                    <fa-icon icon="minus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">
                                                <div class="form-inline">
                                                    <div class="form-group stay-inline">
                                                        <label for="adaptiveSourceFilter">{{ "FILTER" | translate }}:</label>
                                                        <input
                                                            type="text"
                                                            name="adaptiveSourceFilter"
                                                            class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                            [(ngModel)]="adaptiveSourceFilter"
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="
                                                let source of sources
                                                    | multilevelFilter : ['name', 'inputCluster.dns_prefix'] : adaptiveSourceFilter
                                                    | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                            "
                                            [ngClass]="{ 'cursor-pointer row-hover': !source.disable_autopull }"
                                            (click)="!source.disable_autopull && selectAdaptiveSource(source)"
                                        >
                                            <td class="maxw-100px ellipsis">
                                                <zx-source
                                                    [model]="source"
                                                    [showStatusIcon]="true"
                                                    [showStatusText]="false"
                                                    [showLink]="false"
                                                    [showTag]="false"
                                                    [searchTerm]="adaptiveSourceFilter"
                                                ></zx-source>
                                            </td>
                                            <td *ngIf="source.status && source.status.bitrate">{{ source.status.bitrate | number : "1.0-0" }} kbps</td>
                                            <td *ngIf="!source.status || !source.status.bitrate"></td>
                                            <td class="text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                    [disabled]="source.disable_autopull"
                                                    [title]="source.disable_autopull ? ('AUTO_PULL_REQUIRED_FOR_ADAPTIVE' | translate) : ''"
                                                    (click)="selectAdaptiveSource(source)"
                                                >
                                                    <fa-icon icon="plus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="sourcesLoading">
                                        <tr>
                                            <td colspan="3" class="text-center">{{ "LOADING" | translate }}...</td>
                                        </tr>
                                    </tbody>
                                    <tbody
                                        *ngIf="
                                            !sourcesLoading &&
                                            (sources | multilevelFilter : ['name', 'inputCluster.dns_prefix'] : adaptiveSourceFilter).length === 0
                                        "
                                    >
                                        <tr>
                                            <td colspan="3" class="text-center">{{ "NO_SOURCES" | translate }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="form.submitted && selectedBitrates.length === 0" class="invalid-feedback">
                                {{ "AT_LEAST_1_SOURCE_IS_REQUIRED" | translate }}.
                            </div>
                            <div
                                class="mt-2"
                                *ngIf="sources && (sources | multilevelFilter : ['name', 'inputCluster.dns_prefix'] : adaptiveSourceFilter).length > pageSize"
                            >
                                <ngb-pagination
                                    class="mb-0"
                                    [collectionSize]="(sources | multilevelFilter : ['name', 'inputCluster.dns_prefix'] : adaptiveSourceFilter).length"
                                    [(page)]="page"
                                    [pageSize]="pageSize"
                                    [maxSize]="2"
                                ></ngb-pagination>
                            </div>
                        </div>
                    </section>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="channel.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>

                    <!-- Advanced -->
                    <div class="toggle-fieldset-title" [ngClass]="{ closed: !showAdvanced }">
                        <h3
                            class="toggle-fieldset"
                            (click)="showAdvanced = !showAdvanced"
                            [ngClass]="[
                                !showAdvanced ? 'closed' : '',
                                form.submitted &&
                                (form.controls.hls_chunks?.errors ||
                                    form.controls.hls_chunk_duration?.errors ||
                                    form.controls.dash_chunks?.errors ||
                                    form.controls.dash_chunk_duration?.errors)
                                    ? 'is-invalid'
                                    : ''
                            ]"
                        >
                            {{ "ADVANCED" | translate }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showAdvanced"></fa-icon
                            ><fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showAdvanced"></fa-icon>
                        </h3>
                    </div>
                    <fieldset class="mb-3 bordered no-bg" [ngClass]="{ 'd-none': !showAdvanced }">
                        <fieldset class="form-group bordered">
                            <div class="form-group mb-1">
                                <!-- HLS -->
                                <div class="form-check form-check-inline">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="do_hls"
                                        name="do_hls"
                                        [(ngModel)]="channel.disable_hls"
                                        [ngClass]="{ 'is-invalid': form.submitted && channel.disable_cmaf && channel.disable_hls }"
                                        invertChecked
                                    />
                                    <label class="form-check-label semibold" for="do_hls"
                                        >{{ "HLS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                                <!-- DASH -->
                                <div class="form-check form-check-inline">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="do_cmaf"
                                        name="do_cmaf"
                                        [(ngModel)]="channel.disable_cmaf"
                                        [ngClass]="{ 'is-invalid': form.submitted && channel.disable_cmaf && channel.disable_hls }"
                                        invertChecked
                                    />
                                    <label class="form-check-label" for="do_cmaf"
                                        >{{ "CMAF_DASH_fMP4" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class="row">
                                    <div class="col">
                                        <label for="hls_chunks" [ngClass]="{ 'is-invalid': form.submitted && hls_chunks.errors }">{{
                                            "CHUNKS" | translate
                                        }}</label>
                                        <input
                                            type="number"
                                            id="hls_chunks"
                                            name="hls_chunks"
                                            class="form-control form-control-sm"
                                            [(ngModel)]="channel.hls_chunks"
                                            [min]="1"
                                            [max]="50"
                                            #hls_chunks="ngModel"
                                            [ngClass]="{ 'is-invalid': form.submitted && hls_chunks.errors }"
                                        />
                                        <div *ngIf="hls_chunks.invalid" class="invalid-feedback">
                                            <div *ngIf="hls_chunks.errors.min || hls_chunks.errors.max">
                                                {{ "HLS_CHUNKS" | translate }} {{ "MUST_BE_BETWEEN_1_AND_50" | translate }}.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <label for="hls_chunk_duration" [ngClass]="{ 'is-invalid': form.submitted && hls_chunk_duration.errors }"
                                            >{{ "DURATION" | translate }} [{{ "SECONDS" | translate | lowercase }}]</label
                                        >
                                        <input
                                            type="number"
                                            id="hls_chunk_duration"
                                            name="hls_chunk_duration"
                                            class="form-control form-control-sm"
                                            [(ngModel)]="channel.hls_chunk_duration"
                                            [min]="1"
                                            [max]="20"
                                            #hls_chunk_duration="ngModel"
                                            [ngClass]="{ 'is-invalid': form.submitted && hls_chunk_duration.errors }"
                                        />
                                        <div *ngIf="hls_chunk_duration.invalid" class="invalid-feedback">
                                            <div *ngIf="hls_chunk_duration.errors.min || hls_chunk_duration.errors.max">
                                                {{ "HLS_CHUNK_DURATION" | translate }} {{ "MUST_BE_BETWEEN_1_AND_20" | translate }}.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <small>
                                    {{ "REQUIRES_VERSION_12_BROADCASTER_LEAVE_BLANK_TO_USE_BROADCASTER_CONFIGURATION" | translate }}
                                </small>
                            </div>
                        </fieldset>

                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="webvtt_cc" name="webvtt_cc" [(ngModel)]="channel.webvtt_cc" />
                                <label class="form-check-label" for="webvtt_cc"
                                    >{{ "CONVERT_CEA708_TO_WEBVTT" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>

                        <!-- Timecode configuration -->
                        <fieldset class="form-group">
                            <legend for="timecode_mode">
                                {{ "TIMECODE_CONFIGURATION" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </legend>
                            <mat-button-toggle-group name="timecode_mode" aria-label="timecode mode" [(ngModel)]="channel.timecode_mode">
                                <mat-button-toggle value="none">{{ "NONE" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="system">{{ "SYSTEM_TIME" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="sei">{{ "SEI_TIMECODES" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <div class="form-group">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="disable_synchronization"
                                    name="disable_synchronization"
                                    [(ngModel)]="channel.disable_synchronization"
                                />
                                <label class="form-check-label" for="disable_synchronization"
                                    >{{ "DISABLE_RENDITION_SYNCHRONIZATION" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>
                        <!-- Drop unaligned segments -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="strict_chunks" name="strict_chunks" [(ngModel)]="channel.strict_chunks" />
                                <label class="form-check-label" for="strict_chunks"
                                    >{{ "ADAPTIVE_STRICT_SEGMENTS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>

                        <!-- Segment on SCTE-35 -->
                        <div class="form-group">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="segment_on_scte35"
                                    name="segment_on_scte35"
                                    [(ngModel)]="channel.segment_on_scte35"
                                />
                                <label class="form-check-label" for="segment_on_scte35"
                                    >{{ "SEGMENT_ON_SCTE35" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>

                        <!-- Don't add EXT-X-CUE-IN -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="skip_cue_in" name="skip_cue_in" [(ngModel)]="channel.skip_cue_in" />
                                <label class="form-check-label" for="skip_cue_in"
                                    >{{ "SKIP_CUE_IN" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>
                        <!-- SCTE-35 TAGS -->
                        <app-select-generic-field
                            valueToBind="value"
                            [title]="'SCTE-35 tag' | translate"
                            [formControl]="scte35TagControl"
                            [isParentFormSubmitted]="submitted"
                            [items]="scte35TagOptions"
                        >
                        </app-select-generic-field>

                        <!-- Segments tags -->
                        <app-input-generic-field
                            type="number"
                            [formControl]="appearanceRateControl"
                            [textFieldName]="'Segments between EXT-X-PROGRAM-DATE-TIME tags' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="submitted"
                        ></app-input-generic-field>

                        <!-- Drop first segment -->
                        <div class="form-group">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="drop_first_segment"
                                    name="drop_first_segment"
                                    [(ngModel)]="channel.drop_first_segment"
                                />
                                <label class="form-check-label" for="drop_first_segment"
                                    >{{ "DROP_FIRST_SEGMENT" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>
                        <!-- Support MultiAudio -->
                        <div class="form-group">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="support_multiaudio"
                                    name="support_multiaudio"
                                    [(ngModel)]="channel.support_multiaudio"
                                />
                                <label class="form-check-label" for="support_multiaudio"
                                    >{{ "SUPPORT_MULTIAUDIO" | translate }} <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </label>
                            </div>
                        </div>
                        <!-- Ascending Playlist Order -->
                        <div class="form-group">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="order_ascending"
                                    name="order_ascending"
                                    [(ngModel)]="channel.order_ascending"
                                />
                                <label class="form-check-label" for="order_ascending"
                                    >{{ "ASCENDING_PLAYLIST_ORDER" | translate }} <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </label>
                            </div>
                        </div>
                    </fieldset>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

                    <hr class="mt-0" />

                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" *ngIf="!isEdit && !isClone" (click)="back()" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
